import clsx from 'clsx';
import React, { RefObject } from 'react';

export interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: any) => Promise<void> | unknown;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  style?: object;
}

export const Button = React.forwardRef(function Button(
  {
    children,
    className,
    onClick,
    color,
    backgroundColor,
    disabled,
    style,
  }: ButtonProps,
  ref: RefObject<HTMLButtonElement>
) {
  const bgColor = backgroundColor
    ? backgroundColor
    : color === 'text-black'
    ? 'bg-black'
    : 'bg-white';

  const disabledClass = disabled ? 'pointer-events-none opacity-70' : '';
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={clsx(
        'px-4 rounded-sm whitespace-nowrap h-10 block font-semibold hover:cursor-pointer',
        bgColor,
        color,
        disabledClass,
        className
      )}
      style={style}
    >
      {children}
    </button>
  );
});

Button.defaultProps = {
  color: 'text-white',
  backgroundColor: 'bg-black',
};
