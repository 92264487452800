import { useState, createContext, useEffect } from 'react';
import { WalletProvider } from 'hooks/wallet';
import { ToastContainer } from 'react-toast';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/globals.css';
import { TxLoaderState } from '@components/TxLoader';
import TxLoaderModal from '@components/TxLoader';
import { ApolloProvider } from '@apollo/client';
import { getClient } from 'db/contentAPI';
import Router from 'next/router';
import LoadingPage from '@components/LoadingPage';
import Script from 'next/script';

const defaultAppContext = {
  setTxState: () => {
    // no-op
  },
  txState: {
    state: null,
    txHash: null,
  },
};
export const AppContext = createContext<{
  setTxState: React.Dispatch<React.SetStateAction<TxLoaderState>>;
  txState: TxLoaderState;
}>(defaultAppContext);

function ObscuraApp({ Component, pageProps }) {
  const [txState, setTxState] = useState<TxLoaderState>({
    state: null,
    txHash: null,
  });

  const [loading, setLoading] = useState(false);
  /**
   * Show loading state if any page transition takes longer than 100ms
   * Provides feedback to user if server side rendering is hitting API
   */
  useEffect(() => {
    let timeout;
    const start = () => {
      timeout = setTimeout(() => setLoading(true), 100);
    };
    const end = () => {
      clearTimeout(timeout);
      setLoading(false);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      clearTimeout(timeout);
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  return (
    <AppContext.Provider value={{ txState, setTxState }}>
      <ApolloProvider client={getClient()}>
        <WalletProvider>
          {loading ? <LoadingPage /> : <Component {...pageProps} />}

          <ToastContainer delay={4000} position="bottom-right" />
          <TxLoaderModal txState={txState} setTxState={setTxState} />
          <Script
            src="https://cdn.weglot.com/weglot.min.js"
            onLoad={() => {
              // if (window['Weglot'] !== undefined) {
              //   window['Weglot'].initialize({
              //     api_key: 'wg_4a92ffb1e8753b95168f6a742b0961284',
              //   });
              // }
            }}
          />
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-6LBFX07Y8N"
            onLoad={() => {
              if (window['dataLayer'] !== undefined) {
                window['dataLayer'] = window['dataLayer'] || [];
                function gtag(...args) {
                  window['dataLayer'].push(args);
                }
                gtag('js', new Date());
                gtag('config', 'G-6LBFX07Y8N');
              }
            }}
          />
        </WalletProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export default ObscuraApp;
