import clsx from 'clsx';
import React, { RefObject } from 'react';

export const ContentPadding = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={clsx('px-4 sm:px-8 md:px-36', className)}>{children}</div>
  );
};

export const FullBleedPadding = React.forwardRef(function FullBleedPadding(
  {
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode;
  },
  ref: RefObject<HTMLDivElement>
) {
  return (
    <div className={clsx('px-4 sm:px-10 w-full', className)} ref={ref}>
      {children}
    </div>
  );
});
