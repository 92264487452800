import React, { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

const WEB3_MODAL_CONFIG = {
  network: process.env.NEXT_PUBLIC_NETWORK,
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        pollingInterval: 20000000,
        rpc: {
          1: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
        },
      },
    },
  }, // Add other providers here
};

const defaultWalletContext = {
  connectWallet: () => {},
  isConnected: false,
  displayName: '',
  signer: undefined,
  provider: undefined,
};

const WalletContext = createContext<{
  connectWallet: () => void;
  isConnected: boolean;
  displayName: string;
  signer: ethers.providers.JsonRpcSigner | undefined;
  provider: ethers.providers.Provider | undefined;
}>(defaultWalletContext);

type WalletProviderProps = {
  children: React.ReactNode;
};

export const WalletProvider = (props: WalletProviderProps) => {
  const wallet = useWallet();

  return (
    <WalletContext.Provider value={wallet}>
      {props.children}
    </WalletContext.Provider>
  );
};

function useWallet() {
  const [isConnected, setIsConnected] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
  const [provider, setProvider] = useState<ethers.providers.Provider>();

  useEffect(() => {
    initWallet();
  }, []);

  async function initWallet() {
    setProvider(null);
    let savedWallet = null;

    try {
      savedWallet = localStorage
        ? localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')
        : null;
    } catch (error) {
      console.log('Init Error: ', error);
    }

    if (savedWallet) {
      connectWallet();
    }
  }

  async function updateAccount(
    provider: ethers.providers.Provider,
    address: string
  ) {
    let ensName, balance;

    try {
      ensName = await provider.lookupAddress(address);
      balance = await provider.getBalance(address);
      setDisplayName(ensName ?? truncateAddress(address));
    } catch (e) {
      setDisplayName(truncateAddress(address));
    }
  }
  async function connectWallet() {
    const web3Modal = new Web3Modal(WEB3_MODAL_CONFIG);
    try {
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      setSigner(signer);
      setProvider(provider);
      setIsConnected(true);
      const address = await signer.getAddress();
      if (address) {
        await updateAccount(provider, address);
      }
      if (connection) {
        connection.on('accountsChanged', async (accounts: string[]) => {
          if (accounts?.length > 0) {
            await updateAccount(provider, accounts[0]);
          } else {
            disconnect();
          }
        });
      }
      // TODO: update if user changes accounts in MM
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  async function disconnect() {
    if (provider) {
      provider.removeAllListeners();
    }
    if (localStorage) {
      localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    }

    setIsConnected(false);
    setDisplayName('');
  }

  return {
    connectWallet,
    isConnected,
    displayName,
    signer,
    provider,
  };
}

export function useWalletContext() {
  return useContext(WalletContext);
}

function truncateAddress(address: string) {
  if (!address) return "";
  return address.slice(0, 5) + "..." + address.slice(-5);
}
