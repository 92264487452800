import React from "react";
import { Dialog } from "@headlessui/react";
import PuffLoader from "react-spinners/PuffLoader";

export default function TxLoaderModal({
  txState,
}: {
  txState: TxLoaderState;
  setTxState: (txState: TxLoaderState) => void;
}) {
  // const closeLoader = () => {
  //     setTxState({
  //         txHash: "",
  //         state: null,
  //     })
  // }

  return (
    <>
      <Dialog
        className={`fixed inset-0 z-10 overflow-y-auto h-full w-full ${
          txState.state == TX_STATE.APPROVE ? 'opacity-80 bg-black' : ''
        }`}
        open={
          txState.state == TX_STATE.IN_PROGRESS ||
          txState.state == TX_STATE.APPROVE
        }
        onClose={() => {
          console.log('close');
        }}
      >
        {txState.state == TX_STATE.IN_PROGRESS && (
          <>
            <div className="absolute w-full h-full opacity-80 bg-white z-1" />

            <div className="absolute inset-0 w-full h-full z-10 flex flex-col">
              <div className="flex flex-col m-auto text-center bg-white opacity-100 w-auto px-20 h-auto py-10 border-solid border-2 border-sky-500 justify-center">
                <h1 className="font-semibold text-black font-bold text-3xl">
                  Transaction In Progress
                </h1>

                <h5 className="mt-5" style={{ fontSize: '1rem' }}>
                  Please wait while your transaction is being processed. <br />
                  {txState?.txHash && (
                    <>
                      You can check the transaction status on{' '}
                      <a
                        className="font-semibold hover:opacity-80"
                        href={`${explorerUrl()}${txState?.txHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Etherscan
                      </a>
                      .
                    </>
                  )}
                </h5>

                <div className="flex flex-row mt-5">
                  <PuffLoader color={'black'} css={'margin: auto;'} size={60} />
                </div>
              </div>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
}


const explorerUrl = () => {
    if (process.env.NEXT_PUBLIC_NETWORK == 'rinkeby') {
        return 'https://rinkeby.etherscan.io/tx/'
    } else if (process.env.NEXT_PUBLIC_NETWORK == 'mainnet') {
        return 'https://etherscan.io/tx/'
    }
}

export type TxLoaderState = {
    state: TX_STATE;
    txHash?: string;
};

export enum TX_STATE {
    APPROVE,
    IN_PROGRESS,
}