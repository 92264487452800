import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import clsx from 'clsx';
import { AnchorHTMLAttributes } from 'react';
import { Button, ButtonProps } from './Button';

interface LinkProps extends NextLinkProps {
  className?: string;
  rel?: AnchorHTMLAttributes<unknown>['rel'];
  children: React.ReactNode;
}

export function TextLink({ children, rel, className, ...props }: LinkProps) {
  return (
    <NextLink {...props}>
      <a rel={rel} className={clsx('underline hover:text-gray-600', className)}>
        {children}
      </a>
    </NextLink>
  );
}

type ButtonLinkProps = LinkProps & ButtonProps;

export function ButtonLink({
  children,
  rel,
  className,
  backgroundColor,
  color,
  disabled,
  ...props
}: ButtonLinkProps) {
  return (
    <NextLink {...props} passHref>
      <a rel={rel}>
        <Button
          backgroundColor={backgroundColor}
          color={color}
          disabled={disabled}
          className={className}
        >
          {children}
        </Button>
      </a>
    </NextLink>
  );
}
