import Link from "next/link";
import { useWalletContext } from 'hooks/wallet';
import { gql } from '@apollo/client';
import { FullBleedPadding } from './ContentPadding';
import { LogoHorizontal } from './Logo';
import { routes } from '@utils/routes';

export function Header() {
  const { connectWallet, isConnected, displayName } = useWalletContext();

  const style = {
    bgColor: ' bg-white text-black',
    links: ' hover:text-gray-600 text-black uppercase font-semibold',
    buttonBg: ' bg-black',
    buttonText: ' text-white',
    dropdownRingShadow: ' ring-black',
  };

  // https://github.com/ObscuraDAO/marketplace/blob/main/components/Navbar.tsx
  return (
    <FullBleedPadding className={'py-2 top-0 sticky z-20' + style.bgColor}>
      <div className="cursor-pointer"></div>
      <div className="flex justify-between">
        <div className=" sm:flex sm:flex-wrap items-center w-fullspace-x-reverse  sm:space-x-10 py-4 text-xs sm:text-sm md:text-lg font-display capitalize">
          <Link href="/" passHref>
            <h2 className={'font-semibold' + style.links}>
              <LogoHorizontal />
            </h2>
          </Link>
          <div className="relative">
            <Link href="/projects">
              <a className={style.links}>Projects</a>
            </Link>
          </div>
          {/* <div>
            <Link href={routes.howItWorks}>
              <a className={style.links}>Patron Pass</a>
            </Link>
          </div> */}
          <div>
            <Link href="/collections">
              <a className={style.links}>Collections</a>
            </Link>
          </div>
          <div>
            <Link href="/about">
              <a className={style.links}>About</a>
            </Link>
          </div>
          <div>
            <a className={style.links} href="https://mirror.xyz/obscuradao.eth">
              Journal
            </a>
          </div>
          <div>
            <Link href="/who-we-are-book">
              <a className={style.links}>WWA Book</a>
            </Link>
          </div>
        </div>

        <div className="flex whitespace-nowrap justify-center items-center">
          {isConnected && (
            <button
              className={
                'px-4 py-1 rounded-sm h-10 hidden sm:block' + style.buttonBg
              }
            >
              <h3 className={'font-medium' + style.buttonText}>
                {displayName}
              </h3>
            </button>
          )}
          {!isConnected && (
            <button
              className={
                'px-4 rounded-sm whitespace-nowrap h-10 hidden sm:block' +
                style.buttonBg
              }
              onClick={connectWallet}
            >
              <h3 className={'font-medium' + style.buttonText}>
                Connect Wallet
              </h3>
            </button>
          )}
        </div>
      </div>
    </FullBleedPadding>
  );
}

gql`
  query GetHeaderCommissionTiers {
    commisionCollection {
      items {
        slug
        name
        description
      }
    }
  }
`;
