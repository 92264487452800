import React, { ReactElement } from "react";
import { Head, HeadProps } from './Head';
import { Header } from './Header';
import { Footer } from './Footer';
import { FooterBanner } from './FooterBanner';
import { FullBleedPadding } from './ContentPadding';
import { ButtonLink } from './Link';
import { useRouter } from 'next/router';

export default function Layout({
  children,
  head,
  noFooter,
}: {
  children: ReactElement | ReactElement[];
  head?: HeadProps;
  noFooter?: boolean;
}) {
  const router = useRouter();
  return (
    <div className="text-sm sm:text-base">
      <Head {...head} />
      {router.isPreview && (
        <FullBleedPadding className="sticky flex flex-row justify-between items-center bg-red-600 text-white py-4">
          <h4 className="text-lg font-semibold  text-white">
            YOU ARE PREVIEWING UNPUBLISHED CHANGES FROM THE CMS
          </h4>
          <ButtonLink
            prefetch={false}
            href="/api/clear-preview-mode"
            backgroundColor="bg-white"
            color="text-black"
          >
            End Preview Mode
          </ButtonLink>
        </FullBleedPadding>
      )}
      <Header />
      <div>{children}</div>
      {
        !noFooter && (
          <>
            <FooterBanner />
            <Footer />
          </>
        )
      }
    </div>
  );
}
