import React, { ReactElement } from 'react';
import Twitter from '../icon/twitter.svg';
import Discord from '../icon/discord.svg';
import { Logo } from '../components/Logo';
import { gql, useQuery } from '@apollo/client';
import { TextLink } from './Link';
import { FullBleedPadding } from './ContentPadding';
import { HOMEPAGE_ID } from 'logic/pages';
import { GetFooterQuery } from 'generated/graphql';

const footerLinkStyle = 'mb-2 self-left';

export function Footer(): ReactElement {
  const { data } = useQuery<GetFooterQuery>(queryFooter);
  return (
    <div className="bg-white">
      <FullBleedPadding className="grid sm:grid-cols-2 gap-x-52 py-12">
        <div className="flex flex-row">
          <div className="mr-10">
            <Logo />
          </div>
          <p>{data?.homepage?.footerBlurb}</p>
        </div>
        <div className="grid sm:grid-cols-2 overflow-hidden">
          <ul className="flex flex-col">
            {data?.homepage?.footerLinksColumnOneCollection.items.map(
              ({ label, url }) => (
                <TextLink
                  key={url}
                  href={url}
                  className={footerLinkStyle}
                  rel="noopener noreferrer"
                >
                  {label}
                </TextLink>
              )
            )}
          </ul>
          <ul className="flex flex-col">
            {data?.homepage?.footerLinksColumnTwoCollection.items.map(
              ({ label, url }) => (
                <TextLink
                  key={url}
                  href={url}
                  className={footerLinkStyle}
                  rel="noopener noreferrer"
                >
                  {label}
                </TextLink>
              )
            )}
          </ul>
        </div>
      </FullBleedPadding>
      <FullBleedPadding className="py-10 grid grid-cols-2">
        <div className="flex space-x-2 text-left">
          <a
            href="https://twitter.com/obscuradao"
            className="self-center"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter className="fill-current text-black w-6 h-6 mx-2 hover:opacity-50" />
          </a>
          <a
            href="https://discord.gg/obscura"
            className="self-center"
            target="_blank"
            rel="noreferrer"
          >
            <Discord className="fill-current text-black w-6 h-6 mx-2 hover:opacity-50" />
          </a>
        </div>
      </FullBleedPadding>
    </div>
  );
}

const queryFooter = gql`
  query GetFooter {
    homepage(id: "${HOMEPAGE_ID}") {
      footerBlurb
      footerLinksColumnOneCollection {
        items {
          label
          url
        }
      }
      footerLinksColumnTwoCollection {
        items {
          label
          url
        }
      }
    }
  }
`;
