import { Button } from './Button';
import { FullBleedPadding } from './ContentPadding';

export const FooterBanner = () => {
  return (
    <FullBleedPadding className="py-10 sm:py-20 bg-gray-300 mt-8">
      <h3 className="text-5xl font-semibold">
        Become an Obscurian! Join our vibrant and welcoming community of
        artists, collectors, and web3 enthusiasts.
      </h3>
      <a
        className="mt-14 flex"
        href="https://discord.com/obscura"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Join Discord</Button>
      </a>
    </FullBleedPadding>
  );
};
