import { PuffLoader } from 'react-spinners';
import { ContentPadding } from './ContentPadding';
import Layout from './Layout';

export default function LoadingPage() {
  return (
    <Layout>
      <ContentPadding>
        <div className="mx-auto w-full flex flex-1 h-screen justify-center items-center">
          <PuffLoader />
        </div>
      </ContentPadding>
    </Layout>
  );
}
