import { ApolloClient, InMemoryCache } from '@apollo/client';

export const getClient = (preview?: string) => {
  if (preview) {
    console.log('preview mode is enabled');
  }
  return new ApolloClient({
    uri: `https://graphql.contentful.com/content/v1
/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}
/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${
        preview ?? process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY
      }`,
    },
  });
};