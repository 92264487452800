import React, { ReactElement } from "react";
import { default as HTMLHead } from "next/head";

export interface HeadProps {
  title?: string;
  image?: string;
  description?: string;
}

export function Head(props: HeadProps): ReactElement {
  const { title, image, description } = props;

  return (
    <HTMLHead>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.obscura.io/" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.obscura.io/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </HTMLHead>
  );
}

Head.defaultProps = {
  title: "Obscura",
  description: "We fund photographers to create their dream projects.",
  image: "https://obscura.io/meta.png",
}
